.header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

}

.header h4 {
    min-height: 235px;
    display: grid;
    place-items: center;
    padding: clamp(4rem, 10vw + 1rem, 5rem) 0;
    background-color: #1f1f1f;
    width: 100%;
    color: #fff;
    font-size: clamp(36px, 5vw + 1rem, 56px);
    font-weight: 700;
    letter-spacing: .5px;
}