.search .input__group{
    column-gap: .5rem;
}

.search input{
    border-radius: 8px;
    padding: .375rem 1rem;
    margin: 0;
    height: 44px;
}

.search button{
    color: #333;
    border-radius: 8px !important;
    position: relative;
    border: 1px solid var(--primary) !important;
    overflow: hidden;
    isolation: isolate;
    height: 44px;
}

.search button::before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 100%;
    left: 0;
    border-radius: 50px;
    background-color: var(--primary);
    z-index: -1;
    transition: all .2s cubic-bezier(.65,.05,.36,1);
}

.search button:hover::before{
    bottom: 0;
    border-radius: 0px;
}

.search button:active{
    transform: scale(.92);
}