.input__con {
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 8px;
    height: 48px;
    padding: 0 .375rem;
}

.input__con input {
    border: unset;
    width: 32px;
    text-align: center;
    color: #111;
}

.input__con input:focus{
    outline: none;
    box-shadow: unset !important;
}

.input__con input[type=number]::-webkit-inner-spin-button,
.input__con input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;

    margin: 0;
}

.input__con input[type=number] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

.input__con button {
    border: unset;
    background: unset;
    color: #999;
}

.input__con button:hover{
    color: #777;
}

.input__con button:active{
    color: #111;
    transform: scale(.85);
}

.con {
    display: flex;
    height: 100%;
    align-items: center;
}