.card {
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid hsl(0, 0%, 87%);
    padding: 4rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}


.icon__con {
    border-radius: 50%;
    padding: 2rem;
    color: #555;
    margin-bottom: 1rem;
    background: #eaeaea;
}

.title {
    font-size: 1.5;
    margin-bottom: 1rem;
    font-size: 1.75rem;
    font-weight: 700;
    color: #111;
    letter-spacing: .25px;
}

.content {
    text-align: center;
    margin: auto;
    font-size: 1rem;
    letter-spacing: .25px;
    color: #555;
    margin-bottom: 2rem;
    width: 60%;
}

.btn{
    --padding: 0 3rem;
}