.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f6f6f6;
    z-index: 1999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.5rem 1.5rem;
    background-color: #fff;
    /* box-shadow: 0 0 5px 1px #d2cbcb7d; */
    border-radius: 4px;

}

.img__con{
    height: 300px;
    margin-top: .75rem;
    margin-bottom: 1.5rem;
}

.img__con img{
    width: 100%;
    height: 100%;
}

.content {
    text-align: center;
    padding: 0 2rem;
    font-size: 16px;
    color: #555;
    letter-spacing: .25px;
    max-width: 52ch;
    margin: auto;
}

.btn__con {
    --padding: 0 3rem;

    margin-top: 2rem;
}

.btn__con button {
    width: fit-content;
    margin: auto;

}

.icon__con {
    margin-bottom: 1.5rem;
    color: #414141;
}