.wrapper {
    padding: 0 3rem;
}

.img__con {
    max-width: 450px;
    min-width: 200px;
    margin: auto;
    margin-bottom: 2rem;
}

.img__con img {
    height: 100%;
    width: 100%;
}

.wrapper h4 {
    text-align: center;
    color: #333;
    text-transform: capitalize;
}

.wrapper p {
    text-align: center;
    color: #555;
    letter-spacing: .25px;
    font-size: 1.06rem;
}

@media screen and (max-width: 575px) {
    .wrapper h4 {
        font-size: 1.2rem;
    }

    .wrapper p {
        font-size: 1rem;
    }
}