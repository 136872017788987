.spinner__con {
    height: 100%;
    width: 100%;
    position: absolute;
    display: grid;
    place-items: center;
}

.spinner {
    width: 3rem;
    height: 3rem;
    border-width: 0.35rem;
}