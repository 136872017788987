.spinner__con {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    display: grid;
    place-items: center;
    background-color: #fff;
    z-index: 2000;
}

.spinner {
    --bs-spinner-width: 5rem;
    --bs-spinner-height: 5rem;
    --bs-spinner-border-width: 0.45em;
}