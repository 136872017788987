.rtl {
    direction: rtl !important;
}

.card {
    background: #FFFFFF;

    /* rgba(0, 0, 0, 0.1) */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
}

.img__con {
    position: relative;
    overflow: hidden;
    border-radius: clamp(8px, .875vw, 16px);
    aspect-ratio: 4/3;
    max-height: 400px;
}

.img__con img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
    transition: all .4s ease;
}

.img__con:hover img {
    transform: scale(110%);
}

.img__con>span>span {
    line-height: unset;
}

.img__header {
    padding: .75rem;
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
}

.wishlist {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #FFFFFF;
    border-radius: 8px;
    color: #777;
}

.wishlist:hover {
    color: #111;
}

.wishlist:active {
    transform: scale(.90);
}

.item__active {
    color: var(--primaryHover) !important;
}

.new {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 52px;
    height: 24px;
    background: #FA3333;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 1;
    letter-spacing: .5px;
    text-transform: capitalize;
    color: #FFFFFF;
}

.overlay {
    cursor: pointer;
    position: absolute;
    background-color: #7F7F7F;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
}

.content {
    padding: .75rem 1rem 1.25rem;
}

.content p {
    color: #555;
    letter-spacing: .25px;
}

.cats,
.cats a {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: .5px;
    color: #777;
}

.title a {
    margin: .375vw 0 1.125vw;
    min-height: 48px;
    font-style: normal;
    font-weight: 600;
    font-size: clamp(16px, 1vw + .625rem, 18px);
    line-height: 128%;
    letter-spacing: .25px;
    text-transform: capitalize;
    color: #333333;
    display: block;
}


.in_stock,
.available,
.out_of_stock {
    margin-bottom: 3px;
    letter-spacing: .25px;
}

.in_stock {
    color: rgb(5, 163, 95);
}

.available {
    color: rgb(209, 170, 16);
}

.out_of_stock {
    color: #FC4D36
}

.price {
    display: flex;
    align-items: center;
    gap: .625rem;
}

.price .original {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: var(--dark);
}

.price .org_discounted_price {
    font-weight: 400;
    font-size: 15px;
    text-decoration-line: line-through;
    color: #777;
}

.price .discounted_price {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #111;
}

.cart_add_btn {
    --height: 48px;
    --width: 100%;
}

.remove__item {
    display: flex;
    gap: .5rem;
    align-items: center;
}

.remove__item>* {
    flex: .3;
}

.cart_remove_btn {
    --height: 48px;

    flex: .7;
    text-transform: capitalize;
}

.spinner__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
}



/* .spinner__con {
    padding: .2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner__con>div {
    --bs-spinner-width: 1.5rem;
    --bs-spinner-height: 1.5rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-border-width: 0.22em;
} */

@media screen and (max-width: 575px) {
    .content {
        padding: .75rem 4vw 1.25rem;
    }

    .title a {
        font-size: calc(12px + 1.5vw);
    }
}


@media screen and (max-width: 480px) {
    .product {
        padding: 0 calc(2.5% + 2.5vw);
    }
}