.item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4rem;
    padding: 0 3rem 2rem;
}

.rtl {
    direction: rtl !important;
}

.start {
    flex: .6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.end {
    padding: .875rem;
    flex: .4;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img__con {
    background: #fff;
    width: clamp(305px, 30vw, 375px);
    /* height: clamp(265px, 27vw, min(350px, 25vw)); */
    border-radius: 16px;
    overflow: hidden;
    box-shadow: var(--x-offset, 13px) var(--y-offset, 13px) 0 0 #fff;
    aspect-ratio: 1;
}

.img__con img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    border: 1px solid #dddddd5c;
    border-radius: 17px;
}

.content {
    width: 100%;
}

.content h2 {
    font-style: normal;
    font-weight: 700;
    font-size: clamp(2.5rem, 2.5vw + 1rem, 4.5rem);
    line-height: 110%;
    color: #FFFFFF;
    margin-bottom: .875rem;
}

.content p {
    font-style: normal;
    font-weight: 400;
    font-size: clamp(16px, .75vw + .5rem, 18px);
    line-height: 170%;
    letter-spacing: .5px;
    color: #AAAAAA;
    margin-bottom: calc(1rem + 2.125vw);
    width: 85%;
    word-break: keep-all;
}

.content .btn {
    --height: 44px;
    --padding: 0 36px;
}


@media screen and (max-width: 991px) {
    .item {
        padding: unset;
    }

    .content p {
        width: unset;
    }
}

@media screen and (max-width:767px) {
    .item {
        flex-direction: column-reverse;
        align-items: center;
        text-align: center !important;
        gap: 2rem
    }

    .end {
        min-height: unset;
    }

    .img__con {
        box-shadow: unset;
        aspect-ratio: 4/3;
    }

    .content h2 {
        font-size: clamp(2rem, 3.5vw + 1rem, 4.5rem);
    }

    .content p {
        width: 85%;
        margin: auto;
        margin-bottom: 1.25rem;
    }

    .btn {
        margin: auto;
    }

}