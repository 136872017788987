:root {
    --lightGray: #f4f4f4;
}

.cats__con {
    position: fixed;
    top: 0;
    right: 0;
}

.cats {
    position: relative;
    min-height: 90px;
    min-width: 300px;
    padding-top: 1rem;
}

.cats__inline__con {
    background-color: #fff;
    overflow-y: scroll;
    height: 100vh;
    height: 100svh;
    width: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    transition: width .4s cubic-bezier(.86, 0, .07, 1);
    -webkit-box-shadow: 0px 0px 2px 0px rgb(143, 143, 143);
    -moz-box-shadow: 0px 0px 2px 0px rgb(143, 143, 143);
    box-shadow: 0px 0px 2px 0px rgb(143, 143, 143);

}

.rtl .cats__inline__con {
    left: unset;
    right: 0 !important;
}

.cats__inline__open {
    width: clamp(332px, 50%, 350px);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #000;
    opacity: 0;
    z-index: 99;
    transition: opacity .4s cubic-bezier(.86, 0, .07, 1);
}

.overlay__open {
    width: 100%;
    opacity: .5;
}

.header {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
}

.title__con {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.title__con h4 {
    margin: 0;
    font-weight: 600;
    color: var(--dark);
    padding: .25rem 0;
}

.close__con {
    cursor: pointer;
    color: #999;
}

.close__con:active {
    color: #555;
}

/* Only Link Categories */
.cat__link__con {
    border: unset;
    padding: 0;
    margin: auto;
    width: 90%;
    background-color: #fff;
    /* border-bottom: 1px solid var(--lightGray, #ededed); */
}

.cat__link {
    padding: 1rem;
    color: #555;
    font-size: 1rem;
    letter-spacing: .25px;
    display: block;
    border-radius: 8px;
}

/* Categories with menu */
.accordion__item {
    border: unset;
    padding: 0;
    margin: auto;
    margin-top: 4px;
    width: 90%;
    background-color: #fff;
}

.accordion__item:last-child {
    border-bottom: none;
}

.cats button {
    color: #555;
    font-size: 1rem;
    letter-spacing: .25px;
    padding: 1.25rem 1rem;
    border-radius: 8px;
}

.cats button:not(.collapsed) {
    --bs-accordion-active-bg: var(--lightGray, #ededed);
    --bs-accordion-active-color: #333;
    box-shadow: unset;
}

.cats button:hover,
.cat__link:hover {
    background-color: var(--lightGray, #ededed);
}

.accordion__body {
    padding: 0;
    border-radius: 8px;
}

.cat__links {
    padding: 0;
    list-style: none;
    margin-top: 3px;
}

.cat__links li {

    border-radius: 8px;
}

.cat__links li:last-child {
    border-bottom: 1px solid var(--lightGra, #ddd);
    border-end-start-radius: 0;
    border-end-end-radius: 0;
}

.cat__links li:hover {
    background-color: var(--lightGray, #ededed);
}

.cat__links li a {
    padding: 1rem 2rem;
    display: block;
    color: #555;
}