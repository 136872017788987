.auth__layout {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}

.rtl {
    direction: rtl;
}

.auth__layout>div {
    flex: .5;
}

.bg {
    overflow: hidden;
    position: relative;
}

.bg img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    transform: scale(1.2);
}

.lang__con {
    position: absolute;
    padding: 1rem 1.5rem;
    top: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: flex-start;
    width: 100%;
}

.bg a.link {
    color: #fff;
    letter-spacing: .25px;
    cursor: pointer;
    text-shadow: 0 0 16px #000;
}

.bg a.link:hover {
    color: var(--primary);
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: -10px 0 20px #00000050;
}

.menu_btn {
    color: #eee;
    text-shadow: 0 0 3px #000;
    padding: 4px 12px;
}

/* @media screen and (min-width: 1500px){
    .bg img{
        transform: scale(1.4);
    }
} */

@media screen and (max-width: 991px) {
    .auth__layout .content {
        flex: .6;
    }

    .auth__layout .bg {
        flex: .4;
    }
}

@media screen and (max-width: 767px) {
    .auth__layout {
        display: flex;
    }

    .bg img {
        display: none;
    }

    .content {
        flex: 100% !important;
        box-shadow: unset;
        padding-inline: calc(.5rem + .5vw);
    }
}