.lang__btn{
    --bs-btn-bg: unset;
    --bs-btn-color: #a0a0a0;
    --bs-btn-border-color: #a0a0a0;
    
    /* Hover */
    --bs-btn-hover-bg: unset;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-border-color: #fff;
    
    /* Active */
    --bs-btn-active-color: var(--primary);
    --bs-btn-active-bg: unset;
    --bs-btn-active-border-color: var(--primary);
    
    font-size: 15px;
    letter-spacing: .25px;
    padding: .5rem 1.5rem;
    transition: unset;
}

.lang__btn svg{
    margin-inline-start: .25rem;
}

.lang__dropdown{
    --bs-dropdown-min-width: 116px !important;
}