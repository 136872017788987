.nav {
    background-color: var(--dark);
}

.prop {
    height: 66px;
    width: 100%;
}

.item__link {
    --bs-navbar-active-color: var(--primaryHover);
    --bs-navbar-nav-link-padding-x: .75rem;

    display: flex;
    color: #d4d4d4;
    letter-spacing: .25px;
    position: relative;
    align-items: center;
    gap: .375rem;
    transition: all .2s ease-in-out;
}

.item__link:hover {
    color: #fff;
    text-shadow: 0 0 .5px #fff;
}

.item__link svg {
    font-size: 18px;
}

.cart__count {
    position: absolute;
    background-color: var(--primary);
    border-radius: 50%;
    font-size: .75rem;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    top: -5px;
    left: 10px;
}

/* ==== Dropdown ==== */

.username {
    font-weight: 600;
    color: #111;
    letter-spacing: .25px;
}

hr {
    border-color: #ddd !important;
}

/* .dropdown__menu {} */

.dropdown__item,
.dropdown__item a {
    padding: .375rem 1rem;
    color: #555;
    font-weight: 500;
    text-align: start;
    --bs-dropdown-link-hover-color: #333;
    --bs-dropdown-link-active-color: #111;
    --bs-dropdown-link-active-bg: var(--primaryOverlay);
}

.dropdown__item svg {
    margin-inline-end: .375rem;
}

.nav-link.active {
    --bs-navbar-active-color: var(--primary);
}

.item__link:is(:focus, :active) {
    color: var(--primaryHover) !important;
}

.icons__con {
    gap: .75rem;
    display: flex;
    align-items: center;
    padding-inline: 1rem;
}

.nav__btn {
    --padding: 0 16px;
    --height: 38px;

    letter-spacing: .25px;
    margin-inline-start: .25rem;
}

/* .rtl__offcanvas {
    left: 0 !important;
    right: unset !important;
} */

.offcanvas__header {
    --bs-offcanvas-padding-x: 1.75rem;

    border-bottom: 1px solid #ddd;
    /* -webkit-box-shadow: 0px 0px 3px 0px rgba(173, 173, 173, 0.75);
    -moz-box-shadow: 0px 0px 3px 0px rgba(173, 173, 173, 0.75);
    box-shadow: 0px 0px 3px 0px rgba(173, 173, 173, 0.75); */
}

.offcanvas__header img {
    height: fit-content;
    width: 96px;
}

.offcanvas__body {
    --bs-offcanvas-bg: #ededed;
    --bs-offcanvas-padding-x: 1.75rem;
}

/* ---- Toggles ---- */
.cat__toggle {
    padding-inline-start: 1rem;
    margin-inline-end: .75rem;
    cursor: pointer;
}

.toggle,
.cat__toggle {
    color: #fff;
    transition: color .15s ease-in-out;
    border: unset;
}

.toggle:focus {
    box-shadow: unset;
}

.cat__toggle:active,
.toggle:active {
    transform: scale(.9);
}

.cat__toggle:hover,
.toggle:hover {
    color: var(--primary);
}


/* ===== Media Queries ===== */
@media screen and (max-width: 1199px) {

    .Offcanvas__body {
        --bs-offcanvas-padding-x: 1.75rem;
        --bs-offcanvas-padding-y: .5rem;
    }

    .item__link {
        color: #555;
        --bs-nav-link-padding-y: .75rem;
    }

    .item__link:hover {
        color: #111;
        --bs-nav-link-padding-x: .5rem;
    }

    .icons__con {
        --color: #333;

        margin-top: .75rem;
        padding-inline: unset;
    }
}


/*  Small Devices Nav  */
.sm__offcanvas {
    max-width: 332px !important;
}