.input__group{
    gap: .5rem;
}

.filter label {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.price__filter,
.sort__filter{
    margin: .375rem 0;
}

@media screen and (max-width: 767px){
    .price__filter,
    .sort__filter{
        margin-top: 1rem;
    }

    .form__check{
        margin-top: 2.25rem;
    }

}