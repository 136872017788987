.pagination {
    display: flex;
    gap: .75rem;
    justify-content: center;
    margin-top: 2.2rem;
    margin-bottom: .5rem;
    padding: 0;
}

.pagination li a {
    background-color: #f4f4f4;
}

.pagination li :is(a, span) {
    --bs-pagination-active-bg: var(--primary);
    --bs-pagination-active-color: #333;
    --bs-pagination-disabled-bg: unset;

    border: unset;
    color: #777;
    border-radius: 4px;
}

.pagination li :is(a:hover, span:hover, a:focus, span:focus) {
    background-color: #ededed;
    color: #555;
    box-shadow: unset;
}

.pagination li :is(a:active, span:active) {
    transform: scale(.85);
}