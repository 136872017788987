.text__center {
    text-align: center !important;
}

.m__auto {
    margin: auto !important;
}

.mb__1 {
    margin-bottom: 1rem !important;
}

.mb__2 {
    margin-bottom: 2rem !important;
}

.mt__2 {
    margin-top: 2rem !important;
}

.mt__3 {
    margin-top: 3rem !important;
}