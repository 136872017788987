.slick-dots {
    list-style: none;
    position: unset;
    margin-top: 2rem;
    /* padding: .5rem 0 2.5rem; */
}

.slick-dots li {
    width: 18px;
    margin: 0 3px;
}

.slick-dots li button {
    background-color: #555;
    border-radius: 50%;
    opacity: 1;
    width: 14px;
    height: 14px;
}

.slick-dots li.slick-active button {
    background-color: #fff !important;
}

.slick-dots li button::before {
    display: none;
}

@media screen and (max-width: 767px) {
    .slick-dots {
        padding: .75rem 0 1.25rem;
        margin: 0;
    }
}