:root {
    --dark: #111111;
    --primary: #ffc20c;
    --primaryHover: color-mix(in oklab, var(--primary) 90%, rgb(153, 36, 1) 10%)
        /*#d89009*/
    ;
    --primaryOverlay: #fff7bd;
    /*#ffe7c9*/
    ;
    --navItems: #fff;
    --PDT-title: #ccc;
    --box-shadow: 0 0 1px 1px #ddd;
}

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');

* {
    font-family: "Cairo", sans-serif;
}

/* ======== Global Components (Start) ======== */

html,
body,
#root {
    height: 100%;
}


p {
    text-wrap: pretty;
}

a {
    text-decoration: none;
}

.disabled__link {
    color: #969696 !important;
    cursor: not-allowed !important;
    text-decoration: none !important;
}

/* ---- Navbar ---- */

@media screen and(max-width: 1199px) {
    .nav-link.active:hover {
        padding-left: 0;
        padding-right: 0;
    }
}

/* ---- Dropdown ---- */
.dropdown-menu {
    border: unset;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
    padding: .5rem .25rem;
}

.dropdown-menu .dropdown-item {
    padding: .375rem .75rem;
    color: #555;
    letter-spacing: .25px;
    font-weight: 500;
    text-align: start;
    transition: all .1s ease-in-out;
    font-size: .95rem;
}

.dropdown-menu .dropdown-item:hover {
    color: #111;
    background-color: var(--primary);
    transform: scale(.95);
    border-radius: 8px;
}

.dropdown-menu .dropdown-item:active {
    transform: scale(.90);
    background-color: var(--primaryHover);
    transition: none;
}

/* ---- Navbar-Humberger Menu ---- */

.offcanvas-header .btn-close {
    padding: unset;
}

/* --- Primary Button --- */

/* Main Button */
.btn__primary {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: .25px;
    font-size: 16px;
    font-weight: 600;
    background: var(--primary);
    color: var(--dark);
    border-radius: 8px;
    border: unset;
    gap: 8px;
    height: var(--height, 48px);
    padding: var(--padding, 0 24px);
    width: var(--width);
    position: relative;
    overflow: hidden;
    isolation: isolate;
    transition: color .3s cubic-bezier(.73, -0.01, .24, .98);
}

.btn__primary::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: -100%;
    z-index: -1;
    border-radius: 16px;
    background-color: var(--primaryHover);
    transition: all .35s cubic-bezier(.73, -0.01, .24, .98);
}

.btn__primary:hover::before {
    left: 0;
    border-radius: 8px;
}

.btn__primary:active {
    transform: scale(.95);
}

/* Button Outlined */
.btn__primary[id="variant_2"] {
    border: 1px solid var(--primary);
    background-color: unset;
    color: var(--color, #fff);
}

.btn__primary[id="variant_2"]::before {
    background-color: var(--primary);
    border-radius: 4px;
}

.btn__primary[id="variant_2"]:hover {
    --color: var(--dark);
}

/* Button Basic */
.btn__primary[id="variant_3"] {
    color: var(--color, #555);
    background-color: #f4f4f4;
    transition: unset;
}

.btn__primary[id="variant_3"]::before {
    content: none;
}

.btn__primary[id="variant_3"]:hover {
    color: #333;
    background-color: #eee;
}

/* (input) */

select:focus,
input:focus:not(input[type="checkbox"]:focus) {
    --box-shadow: 0 0 0 3px var(--primaryOverlay);

    border-color: var(--primary) !important;
    box-shadow: var(--box-shadow) !important;
}

input[type="checkbox"] {
    border-color: var(--primary);
}

input[type="checkbox"]:hover {
    background-color: var(--primaryOverlay);
}

input[type="checkbox"]:is(:checked, :focus) {
    border-color: var(--primary);
    box-shadow: unset;
}

input[type="checkbox"]:checked {
    background-color: var(--primary);
}

/* ======== Global Components (End) ======== */

.dropdown-toggle::after {
    display: none;
}

/* Spinner Sizes */
.spinner-lg {
    --bs-spinner-width: 5rem;
    --bs-spinner-height: 5rem;
    --bs-spinner-border-width: 0.45em;
}

.slick-slide>div {
    padding-inline-end: 0rem;
}


/* ================================== */
/* RTL Styles */
/* ================================== */

.rtl .form-control.is-invalid,
.rtl .was-validated .form-control:invalid {
    background-position: left calc(.375em + .1875rem) center;
}

.rtl,
.rtl input {
    direction: rtl !important;
}

.rtl .form-check {
    padding-right: 1.5em;
    padding-left: unset;
}

.rtl .form-check .form-check-input {
    float: right;
    margin-right: -1.5em;
}

.rtl .accordion-button::after {
    margin-right: auto;
    margin-left: unset;
}

.rtl .dropdown-menu-end[data-bs-popper] {
    left: 0;
    right: auto;
}

.rtl .dropdown-menu .dropdown-item {
    text-align: right;
}

.rtl_toast>div {
    direction: rtl !important;

}