.footer {
    padding-top: 3rem;
    padding-bottom: 1rem;
    margin-top: auto;

    /* might be Changed */
    background: #111111;
}

.start {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.img__con {
    height: 60px;
}

.img__con img {
    height: 100%;
}


/* ==== Footer Links ==== */

.end {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5rem;
}

.title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    color: var(--primary);
}

.links ul {
    list-style: none;
    padding: 0;
}

.links ul li>.link {
    cursor: pointer;
    text-decoration: none;
    align-items: center;
    display: flex;
    gap: .4rem;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #AAAAAA;
    margin-top: 1.375rem;
}

.links ul li>.link:hover {
    color: #fff;
}

.links .icon {
    place-items: center;
    display: grid;
    width: 24px;
    height: 24px;
}

/* ==== Copy Right & Made By ==== */
.copyright__con {
    display: flex;
    align-items: center;
    justify-content: space-between;
    letter-spacing: .25px;
    border-top: 1px solid #333;
    padding-top: 1.25rem;
    padding-bottom: .25rem;
    margin-top: 1rem;
    color: #777;
    text-transform: capitalize;
    font-size: 16px;
}

.copyright__con a {
    color: #fff;
}

.copyright__con a:hover {
    text-decoration: underline;
}

.madeBy {
    display: flex;
    align-items: center;
    gap: .2rem;
}

@media screen and (max-width: 991px) {

    .img__con {
        height: 55px;
    }

    .end {
        gap: unset;
    }

    .copyright__con {
        font-size: 15px;
    }

}

@media screen and (max-width: 767px) {

    footer {
        padding-inline: 1rem;
    }

    .start {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 2.5rem;
    }

    .img__con {
        height: 48px;
    }

    .copyright__con {
        flex-wrap: wrap;
        justify-content: center;
        gap: .25rem;
    }
}

@media screen and (max-width: 575px) {

    footer {
        padding-inline: .625rem;
    }

    .img__con {
        height: 44px;
    }

    .end {
        flex-direction: column;
        gap: 2rem;
    }

    .links ul li>.link {
        margin-top: 1.75rem;
    }

    .copyright__con {
        font-size: 14px;
    }

}