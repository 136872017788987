.title {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: var(--dark);
    gap: clamp(1.125rem , 2vw ,1.625rem);
    font-style: normal;
    font-weight: 700;
    font-size: clamp(2.625rem ,5vw + 1rem ,3.5rem);
    margin-bottom: clamp(1.75rem , 4.5vw ,2.75rem);
}

.title::before,
.title::after {
    content: '';
    width: calc(8px + .25vw);
    height: calc(16px + 1vw);
    background-color: var(--primary);
    border-radius: 6px;
    margin-top: 5px;
}

/* @media screen and (max-width: 991px){

    .title::before,
    .title::after {
        width: 8px;
        height: 20px;
    }

}  */