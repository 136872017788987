.slider__con {
    height: fit-content;
    background-color: #111111;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    position: relative;
    direction: ltr;
}